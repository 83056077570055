var app = angular.module('app', ['ui.bootstrap']);

app.controller('contentsController', function($scope, $timeout){

    var url = [location.protocol, '//', location.host, location.pathname].join('');
    var splittedPath = url.split('/');
    var articleId = splittedPath[splittedPath.length - 1];
    
    $scope.sections = [];

    $scope.isArticleActive = function(title)
    {
        return title == articleId;
    };

    $timeout(openActiveSectionOnStart, 1);

    function openActiveSectionOnStart() {

        for (var i = 0; i < $scope.sections.length; i++) {
            var section = $scope.sections[i];

            for (var j = 0; j < section.articles.length; j++) {
                var article = section.articles[j];

                if($scope.isArticleActive(article.id))
                {
                    section.visible = true;
                    break;
                }
            }
        }
    }
});

app.controller('TypeaheadCtrl', function ($scope, $http) {

    $scope.getItems = function(val) {
        return $http.get('/search', {
            params: {
                q: val
            }
        }).then(function(response){
            return response.data.map(function(item){
                return {text: item.title + ' ' + item.subtitle,
                        articleId: item.title.replace(/\.\sčlen/, '')};
            });
        });
    };

    $scope.onSelect = function (model) {

        window.location.href = '/articles/' + model.articleId;
    };

});